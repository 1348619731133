import React from "react";
import { CheckCircle } from "../assets/icons/Icons";
import NavigationBar from "../components/ui/Navbar";
import Footer from "../components/ui/Footer";

const ThankYou = () => {
  return (
    <div className="flex flex-col items-center">
      <NavigationBar />
      <section
        id="thank-you"
        className="px-4 flex flex-col justify-center items-center h-screen"
      >
        <div className="absolute inset-0 bg-dawn bg-cover bg-center bg-no-repeat z-0"></div>
        <p className="text-dark text-3xl md:text-[40px] font-medium font-made-mirage">
          Let's plan your <i className="text-gold font-gt-super">special </i>
          day!
        </p>
        <div className="flex flex-col items-center p-5 md:p-10 bg-[#A18DA31A] border border-[#5A495B80] opacity-100 rounded-3xl mt-10 z-10 w-full md:w-[640px] text-center container">
          <div className="flex flex-col items-center">
            <CheckCircle />
            <p className="text-2xl md:text-3xl font-made-mirage font-medium mt-6">
              Thank you for contacting us!
            </p>
            <p className="md:text-xl text-purple mt-3">
              We will reach out to you within the next 24-48 hours to plan your
              occasion.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ThankYou;
